let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

// env = 'en'; // ENGLISHMODE

let usersDbName = 'users';
let reservedUsersDbName = 'users-reserved';
let areasDbName = 'areas';
let decksDbName = 'decks';
let cardsDbName = 'cards';
let imagesDbName = 'images';
let tagsDbName = 'tags';
let missionsDbName = 'missions';
let surveysDbName = 'surveys';

const appConfig = {
	env: env,
	name: 'mazeout',
	usersDbName: usersDbName,
	reservedUsersDbName: reservedUsersDbName,
	areasDbName: areasDbName,
	decksDbName: decksDbName,
	cardsDbName: cardsDbName,
	imagesDbName: imagesDbName,
	tagsDbName: tagsDbName,
	missionsDbName: missionsDbName,
	surveysDbName: surveysDbName,
	userNameMinLength: 3,
	userNameMaxLength: 15,
	gameDurationLimitSecs: 7 * 60,
	gameDurationSoftLimitSecs: 5 * 60,
	pauseIntervalSecs: 3 * 60,
	cardDetentionDuration: 2, // game days
	tagDetentionDuration: 5, // game days
	tagDetentionAddon: 1, // game days
	inactivityLimitSeconds: 30,
	cookiesAcceptedCookieName: 'mazeout_cookies_accepted',
	cookieGameProgressName: 'mazeout_cookies_progress'
};

export default appConfig;